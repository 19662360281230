import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./MenuItem.module.css";

const MenuItem = ({ to, icon, title, onClick }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem
    }
    onClick={onClick}
  >
    <span>{icon}</span>
    <span>{title}</span>
  </NavLink>
);

export default MenuItem;
